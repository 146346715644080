<template>
  <b-nav-item>
    <feather-icon
      :badge="unread"
      badge-classes="bg-danger"
      size="21"
      icon="SendIcon"
      @click="$router.push({name: 'misc-bulletins'})"
    />
  </b-nav-item>
</template>

<script>
import { ref } from '@vue/composition-api'
import { BNavItem } from 'bootstrap-vue'
import accountServive from '@/services/account.service'

export default {
  components: {
    BNavItem,
  },
  setup() {
    /* Services */
    const {
      fetchUnreadBulletinsCount,
    } = accountServive()

    const unread = ref(0)
    fetchUnreadBulletinsCount(data => {
      unread.value = data
    })
    return {
      unread,
    }
  },
}
</script>
