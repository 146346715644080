export default [
  {
    header: 'Apps',
    icon: 'PackageIcon',
    children: [
      {
        title: 'User',
        icon: 'UserIcon',
        children: [
          {
            title: 'List',
            route: 'apps-users-list',
          },
        ],
      },
    ],
  },
]
