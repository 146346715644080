import axios from 'axios'
import i18n from '@/libs/i18n'
import store from '@/store'

// Definir el enumerado Origen
export const Origen = Object.freeze({
  ARCHIVOS: 'ARCHIVOS',
  EXPEDIENTE_DIGITAL: 'EXPEDIENTEDIGITAL',
  MOCK: 'MOCK',
})

const API_URL = `${process.env.VUE_APP_ADUACORE_API_URL}/api`
const activeDownloads = {}

export async function downloadFile(downloadProps, onComplete, onError, onCancel) {
  const {
    id,
    nombreArchivo,
    titulo,
    descripcion,
    origen,
    item = null,
    mock,
  } = downloadProps

  if (item) {
    item.isDownloading = true
  }

  let downloadId = `${origen}_${id}`
  let url = ''
  let cancelling = false

  switch (origen) {
    case Origen.ARCHIVOS:
      url = `${API_URL}/Archivos/${id}/Streaming`
      break
    case Origen.EXPEDIENTE_DIGITAL:
      url = `${API_URL}/ExpedientesDigitales/Operaciones/${id}/Descarga/Streaming`
      break
    case Origen.MOCK:
      url = ''
      downloadId = `${id}`
      break
    default:
      throw new Error(`Origen desconocido: ${origen}`)
  }

  const source = axios.CancelToken.source()
  activeDownloads[downloadId] = source

  try {
    store.commit('startDownload', {
      id: downloadId,
      name: nombreArchivo,
      title: titulo,
      description: descripcion,
      loaded: 0,
      total: 0,
      progress: 0,
      date: new Date(),
      mock,
    })

    if (mock) return

    const response = await axios({
      url,
      method: 'GET',
      responseType: 'blob',
      headers: {
        downloadId,
      },
      cancelToken: source.token,
      onDownloadProgress: progressEvent => {
        if (!activeDownloads[downloadId]) {
          cancelling = true
          if (onCancel) {
            onCancel(i18n.t('DownloadManager.downloadCancelled', { name: nombreArchivo }))
            if (item) {
              item.isDownloading = false
            }
          }
          source.cancel(i18n.t('DownloadManager.downloadCancelled', { name: nombreArchivo }))
          return
        }

        const progressData = { id: downloadId, loaded: progressEvent.loaded, total: progressEvent.total }
        store.commit('updateDownloadProgress', progressData)

        if (item) {
          item.loaded = progressEvent.loaded
          item.total = progressEvent.total
          item.progress = Math.round((progressEvent.loaded * 100) / progressEvent.total)
        }
      },
    })

    const blobUrl = window.URL.createObjectURL(new Blob([response.data]))
    const link = document.createElement('a')
    link.href = blobUrl
    link.setAttribute('download', nombreArchivo)
    document.body.appendChild(link)
    link.click()
    link.remove()
  } catch (error) {
    if (axios.isCancel(error)) {
      cancelling = true
      if (onCancel) {
        onCancel(i18n.t('DownloadManager.downloadCancelled', { name: nombreArchivo }))
      }
    } else if (onError) {
      onError(error)
    }
  } finally {
    if (item) {
      item.isDownloading = false
    }
    store.commit('finishDownload', downloadId)
    if (onComplete && !cancelling) {
      onComplete(i18n.t('DownloadManager.downloadCompleted', { name: nombreArchivo }))
    }
    delete activeDownloads[downloadId]
  }
}

export async function cancelDownload(downloadId) {
  if (activeDownloads[downloadId]) {
    activeDownloads[downloadId].cancel(i18n.t('DownloadManager.downloadCancelled', { name: '' }))
    delete activeDownloads[downloadId]
    try {
      await axios.post(`${API_URL}/Archivos/Streaming/${downloadId}/Cancel`)
      // console.log(i18n.t('DownloadManager.downloadCancelled', { name: '' }))
    } catch (error) {
      // console.error('Failed to cancel download', error)
    }
  }
}
