export default [
  {
    title: 'Settings',
    icon: 'SettingsIcon',
    tagVariant: 'light-warning',
    children: [
      {
        title: 'Data Access',
        icon: 'DatabaseIcon',
        children: [
          {
            title: 'Condition Filters',
            route: 'settings-dataaccess-conditionfilters',
            icon: 'FilterIcon',
          },
          {
            title: 'Connection Strings',
            route: 'settings-dataaccess-connectionstrings',
            icon: 'Link2Icon',
          },
          {
            title: 'Query Objects',
            route: 'settings-dataaccess-queryobjects',
            icon: 'SearchIcon',
          },
        ],
      },
      {
        title: 'DigitalRecords',
        route: 'apps-digtalrecord-settings',
        icon: 'FolderIcon',
        resource: 'ExpedientesDigitales',
        action: 'write',
      },
      {
        title: 'Dashboards',
        route: 'settings-dashboards-elements',
        icon: 'ActivityIcon',
        resource: 'Tableros',
        action: 'write',
      },
    ],
  },
]
