/* eslint-disable */
// prettier-ignore
import i18n from "../../../../libs/i18n"

export default {
  pages: {
    key: 'title',
    data:
    [
       // *===============================================---*
       // *--------- Operation ---- ---------------------------------------*
       // *===============================================---*
      { title: i18n.t('Classification'), route: { name: 'apps-classification-list' }, icon: 'LayersIcon', isBookmarked: false,  resource: 'Clasificaciones', action: 'read'},
      { title: i18n.t('Confront'), route: { name: 'apps-confronts-warehouse-list' }, icon: 'CheckCircleIcon', isBookmarked: false, resource: 'Confrontas', action: 'read'},
      { title: i18n.t('WarehouseLoad'), route: { name: 'apps-confronts-load-list' }, icon: 'ArrowUpRightIcon', isBookmarked: false,resource: 'Confrontas', action: 'read' },
      { title: i18n.t('Previous'), route: { name: 'apps-previous-list' }, icon: 'PocketIcon', isBookmarked: false, resource: 'Previos', action: 'read' },
       // *--------- Operation - Digital Record ---- ---------------------------------------*
      { title: i18n.t('OperationDrQuery'), route: { name: 'apps-digtalrecord-query' }, icon: 'SearchIcon', isBookmarked: false, resource: 'ExpedientesDigitales', action: 'read' },
      { title: i18n.t('OperationDrDownloads'), route: { name: 'apps-digtalrecord-downloads' }, icon: 'DownloadIcon', isBookmarked: false, resource: 'ExpedientesDigitales', action: 'read'  },
      { title: i18n.t('OperationDrManagement'), route: { name: 'apps-digtalrecord-management' }, icon: 'CommandIcon', isBookmarked: false, resource: 'ExpedientesDigitales', action: 'read'  },
       // *--------- Operation - Audit ---- ---------------------------------------*
      { title: i18n.t('OperationAuditPreaudit'), route: { name: 'auditoria-previa' }, icon: 'TrelloIcon', isBookmarked: false, resource: 'AuditoriaMolex', action: 'read' },
      { title: i18n.t('OperationAuditAuditSAAIM3'), route: { name: 'auditoria-saai-m3' }, icon: 'TrelloIcon', isBookmarked: false, resource: 'AuditoriaMolex', action: 'read' },
      { title: i18n.t('OperationAuditQuery'), route: { name: 'auditoria-molex' }, icon: 'SearchIcon', isBookmarked: false, resource: 'AuditoriaMolex', action: 'read' },
      { title: i18n.t('FollowUp'), route: { name: 'auditoria-seguimiento-molex' }, icon: 'ArrowRightCircleIcon', isBookmarked: false, resource: 'AuditoriaMolex', action: 'read' },
       // *===============================================---*
       // *--------- Help Desk ---- ---------------------------------------*
       // *===============================================---*
      { title: i18n.t('Tickets'), route: { name: 'apps-tickets-list' }, icon: 'InboxIcon', isBookmarked: false, resource: 'MesaAyuda', action: 'read' },
      { title: i18n.t('Categories'), route: { name: 'apps-categories-list' }, icon: 'TagIcon', isBookmarked: false, resource: 'MesaAyuda', action: 'read' },
      { title: i18n.t('UsersActive'), route: { name: 'apps-tickets-user-list' }, icon: 'UsersIcon', isBookmarked: false, resource: 'MesaAyuda', action: 'read' },
      { title: i18n.t('Incidents'), route: { name: 'apps-tickets-incidents-list' }, icon: 'AlertOctagonIcon', isBookmarked: false, resource: 'MesaAyuda', action: 'read' },
             // *===============================================---*
       // *--------- Security ---- ---------------------------------------*
       // *===============================================---*
       { title: i18n.t('Users'), route: { name: 'apps-users-list' }, icon: 'UserIcon', isBookmarked: false, resource: 'Usuarios', action: 'read' },
       { title: i18n.t('PermissionsMatrix'), route: { name: 'apps-security-permissions-matrix' }, icon: 'FileIcon', isBookmarked: false, resource: 'MatrizPermisos', action: 'read' },
       // *===============================================---*
       // *--------- IT ---- ---------------------------------------*
       // *===============================================---*
      { title: i18n.t('Equipment'), route: { name: 'apps-equipment-list' }, icon: 'AirplayIcon', isBookmarked: false, resource: 'MesaAyuda', action: 'read' },
      { title: i18n.t('Softwares'), route: { name: 'apps-software-list' }, icon: 'CloudIcon', isBookmarked: false, resource: 'MesaAyuda', action: 'read' },
      { title: i18n.t('Supplier'), route: { name: 'apps-provider-list' }, icon: 'PackageIcon', isBookmarked: false, resource: 'MesaAyuda', action: 'read' },
      { title: i18n.t('EquipmentType'), route: { name: 'apps-type-equipment-list' }, icon: 'TvIcon', isBookmarked: false, resource: 'MesaAyuda', action: 'read' },
      { title: i18n.t('Brands'), route: { name: 'apps-brands-list' }, icon: 'ShoppingBagIcon', isBookmarked: false, resource: 'MesaAyuda', action: 'read' },
      { title: i18n.t('Models'), route: { name: 'apps-model-list' }, icon: 'BookmarkIcon', isBookmarked: false, resource: 'MesaAyuda', action: 'read' },
      { title: i18n.t('Banks'), route: { name: 'apps-bank-list' }, icon: 'DollarSignIcon', isBookmarked: false, resource: 'MesaAyuda', action: 'read' },
      { title: i18n.t('BankAccount'), route: { name: 'apps-account-bank-list' }, icon: 'CreditCardIcon', isBookmarked: false, resource: 'MesaAyuda', action: 'read' },
       // *===============================================---*
       // *--------- Quality & Security ---- ---------------------------------------*
       // *===============================================---*
      { title: i18n.t('SOC'), route: { name: 'apps-quality-soc' }, icon: 'CircleIcon', isBookmarked: false },
      { title: i18n.t('RAMs'), route: { name: 'apps-requirement-list' }, icon: 'CircleIcon', isBookmarked: false },
       // *===============================================---*
       // *--------- Operative Catalogs ---- ---------------------------------------*
       // *===============================================---*
      { title: i18n.t('Customers'), route: { name: 'apps-customers-list' }, icon: 'BriefcaseIcon', isBookmarked: false, resource: 'Clientes', action: 'read' },
      { title: i18n.t('Vendors'), route: { name: 'apps-vendors-list' }, icon: 'TruckIcon', isBookmarked: false, resource: 'Proveedores', action: 'read' },
      { title: i18n.t('Receivers'), route: { name: 'apps-receivers-list' }, icon: 'TargetIcon', isBookmarked: false, resource: 'Destinatarios', action: 'read' },
      { title: i18n.t('Parts'), route: { name: 'apps-parts-list' }, icon: 'ToolIcon', isBookmarked: false,  resource: 'Partes', action: 'read' },
      { title: i18n.t('Customs'), route: { name: 'apps-custom-list' }, icon: 'MapPinIcon', isBookmarked: false, resource: 'Aduanas', action: 'read' },
      { title: i18n.t('Warehouse'), route: { name: 'apps-bonden-warehouse-list' }, icon: 'GlobeIcon', isBookmarked: false, resource: 'AlmacenesFiscales', action: 'read' },
      { title: i18n.t('Transportation'), route: { name: 'apps-transportation-lines-list' }, icon: 'TruckIcon', isBookmarked: false, resource: 'Transpostistas', action: 'read' },
       // *===============================================---*
       // *--------- System Catalogs ---- ---------------------------------------*
       // *===============================================---*
       // *--------- System Catalogs - Organization Chart ---- ---------------------------------------*
      { title: i18n.t('HierarchicalLevel'), route: { name: 'apps-organization-chart-hierarchical-level-list' }, icon: 'BarChart2Icon', isBookmarked: false, resource: 'RecursosHumanos', action: 'read' },
      { title: i18n.t('Area'), route: { name: 'apps-organization-chart-area-list' }, icon: 'ClipboardIcon', isBookmarked: false, resource: 'RecursosHumanos', action: 'read' },
       // *--------- System Catalogs - Jobs ---- ---------------------------------------*
      { title: i18n.t('Job'), route: { name: 'apps-human-resources-job-list' }, icon: 'BriefcaseIcon', isBookmarked: false, resource: 'RecursosHumanos', action: 'read' },
      { title: i18n.t('JobLaboralFunction'), route: { name: 'apps-human-resources-job-laboral-function-list' }, icon: 'ClipboardIcon', isBookmarked: false, resource: 'RecursosHumanos', action: 'read' },
      { title: i18n.t('JobSchedule'), route: { name: 'apps-human-resources-job-schedule-list' }, icon: 'ClockIcon', isBookmarked: false, resource: 'RecursosHumanos', action: 'read' },
      { title: i18n.t('JobLinePosition'), route: { name: 'apps-human-resources-job-line-position-list' }, icon: 'TrendingUpIcon', isBookmarked: false, resource: 'RecursosHumanos', action: 'read' },
       // *--------- System Catalogs - Human Resource ---- ---------------------------------------*
      { title: i18n.t('WorkArea'), route: { name: 'apps-human-resources-work-area-list' }, icon: 'FolderIcon', isBookmarked: false, resource: 'RecursosHumanos', action: 'read' },
      { title: i18n.t('PaymentTypePeriod'), route: { name: 'apps-human-resources-payment-type-period-list' }, icon: 'CreditCardIcon', isBookmarked: false, resource: 'RecursosHumanos', action: 'read' },
      { title: i18n.t('Profile'), route: { name: 'apps-human-resources-profile-list' }, icon: 'UserIcon', isBookmarked: false },
      { title: i18n.t('ProfileAcademicTraining'), route: { name: 'apps-human-resources-profile-academic-training-list' }, icon: 'FileMinusIcon', isBookmarked: false, resource: 'RecursosHumanos', action: 'read' },
      { title: i18n.t('ProfileHability'), route: { name: 'apps-human-resources-profile-hability-list' }, icon: 'Edit2Icon', isBookmarked: false, resource: 'RecursosHumanos', action: 'read' },
      { title: i18n.t('ProfileLanguage'), route: { name: 'apps-human-resources-profile-language-list' }, icon: 'TypeIcon', isBookmarked: false, resource: 'RecursosHumanos', action: 'read' },
      { title: i18n.t('ProfileSalary'), route: { name: 'apps-human-resources-profile-salary-list' }, icon: 'DollarSignIcon', isBookmarked: false, resource: 'RecursosHumanos', action: 'read' },
      { title: i18n.t('ClassContract'), route: { name: 'apps-human-resources-class-contract-list' }, icon: 'FileTextIcon', isBookmarked: false, resource: 'RecursosHumanos', action: 'read' },
      { title: i18n.t('LaboralDedication'), route: { name: 'apps-human-resources-laboral-dedication-list' }, icon: 'PieChartIcon', isBookmarked: false, resource: 'RecursosHumanos', action: 'read' },
      { title: i18n.t('SchemeTypePayment'), route: { name: 'apps-human-resources-scheme-type-payment-list' }, icon: 'DollarSignIcon', isBookmarked: false, resource: 'RecursosHumanos', action: 'read' },
      { title: i18n.t('AcademicTraining'), route: { name: 'apps-human-resources-academic-training-list' }, icon: 'AwardIcon', isBookmarked: false, resource: 'RecursosHumanos', action: 'read' },
      { title: i18n.t('LaboralFunction'), route: { name: 'apps-human-resources-laboral-function-list' }, icon: 'Edit3Icon', isBookmarked: false, resource: 'RecursosHumanos', action: 'read' },
      { title: i18n.t('Language'), route: { name: 'apps-human-resources-language-list' }, icon: 'TypeIcon', isBookmarked: false, resource: 'RecursosHumanos', action: 'read' },
      { title: i18n.t('HabilityType'), route: { name: 'apps-human-resources-hability-type-list' }, icon: 'PocketIcon', isBookmarked: false, resource: 'RecursosHumanos', action: 'read' },
      { title: i18n.t('Hability'), route: { name: 'apps-human-resources-hability-list' }, icon: 'ToolIcon', isBookmarked: false, resource: 'RecursosHumanos', action: 'read' },
      { title: i18n.t('Schedule'), route: { name: 'apps-human-resources-schedule-list' }, icon: 'WatchIcon', isBookmarked: false, resource: 'RecursosHumanos', action: 'read' },
      { title: i18n.t('Departament'), route: { name: 'apps-human-resources-departament-list' }, icon: 'ArchiveIcon', isBookmarked: false, resource: 'RecursosHumanos', action: 'read' },
      { title: i18n.t('Scholarship'), route: { name: 'apps-human-resources-scholarship-list' }, icon: 'BookOpenIcon', isBookmarked: false, resource: 'RecursosHumanos', action: 'read' },
      { title: i18n.t('CivilStatus'), route: { name: 'apps-human-resources-civil-status-list' }, icon: 'FeatherIcon', isBookmarked: false, resource: 'RecursosHumanos', action: 'read' },
      { title: i18n.t('WorkPlaces'), route: { name: 'apps-human-resources-work-place-list' }, icon: 'MonitorIcon', isBookmarked: false, resource: 'RecursosHumanos', action: 'read' },
      { title: i18n.t('WorkStation'), route: { name: 'apps-human-resources-work-station-list' }, icon: 'BriefcaseIcon', isBookmarked: false, resource: 'RecursosHumanos', action: 'read' },
      { title: i18n.t('Employee Types'), route: { name: 'apps-human-resources-employee-type-list' }, icon: 'UsersIcon', isBookmarked: false, resource: 'RecursosHumanos', action: 'read' },
       // *--------- System Catalogs - Quality & Security ---- ---------------------------------------*
      { title: i18n.t('ActionImprovementProblem'), route: { name: 'apps-action-improvement-problem-list' }, icon: 'AlertOctagonIcon', isBookmarked: false, resource: 'GeneralDatos', action: 'read' },
      { title: i18n.t('Indicator'), route: { name: 'apps-indicator-list' }, icon: 'ArrowUpRightIcon', isBookmarked: false, resource: 'GeneralDatos', action: 'read' },
      { title: i18n.t('RequirementDocumentType'), route: { name: 'apps-requirement-document-type-list' }, icon: 'FolderIcon', isBookmarked: false, resource: 'AccionesMejora', action: 'read' },
      { title: i18n.t('RequirementQualityType'), route: { name: 'apps-requirement-quality-type-list' }, icon: 'CheckIcon', isBookmarked: false, resource: 'AccionesMejora', action: 'read'  },
       // *===============================================---*
       // *--------- TOOLS ---- ---------------------------------------*
       // *===============================================---*
      { title: i18n.t('Bulletins'), route: { name: 'apps-bulletins-list' }, icon: 'SendIcon', isBookmarked: false, resource: 'Boletines', action: 'read' },
       // *===============================================---*
       // *--------- Settings ---- ---------------------------------------*
       // *===============================================---*
       // *--------- Settings - Data Access ---- ---------------------------------------*
      { title: i18n.t('Condition Filters'), route: { name: 'settings-dataaccess-conditionfilters' }, icon: 'FilterIcon', isBookmarked: false },
      { title: i18n.t('Connection Strings'), route: { name: 'settings-dataaccess-connectionstrings' }, icon: 'DatabaseIcon', isBookmarked: false },
      { title: i18n.t('Query Objects'), route: { name: 'settings-dataaccess-queryobjects' }, icon: 'ClipboardIcon', isBookmarked: false },
       // *===============================================---*
       // *--------- Digital Records ---- ---------------------------------------*
       // *===============================================---*
      { title: i18n.t('DigitalRecords'), route: { name: 'apps-digtalrecord-settings' }, icon: 'FolderIcon', isBookmarked: false, resource: 'ExpedientesDigitales', action: 'read' },
       // *===============================================---*
       // *--------- Dashboard ---- ---------------------------------------*
       // *===============================================---*
      { title: i18n.t('Dashboards'), route: { name: 'pages-user-dashboard' }, icon: 'ActivityIcon', isBookmarked: false, resource: 'Tableros', action: 'read' },
      { title: i18n.t('DashboardsSettings'), route: { name: 'settings-dashboards-elements' }, icon: 'ActivityIcon', isBookmarked: false, resource: 'Tableros', action: 'write' },
    ],
  },
  // files: {
  //   key: 'file_name',
  //   data: [
  //     {
  //       file_name: "Joe's CV",
  //       from: 'Stacy Watson',
  //       icon: require('@/assets/images/icons/doc.png'),
  //       size: '1.7 mb',
  //     },
  //     {
  //       file_name: 'Passport Image',
  //       from: 'Ben Sinitiere',
  //       icon: require('@/assets/images/icons/jpg.png'),
  //       size: ' 52 kb',
  //     },
  //     {
  //       file_name: 'Questions',
  //       from: 'Charleen Patti',
  //       icon: require('@/assets/images/icons/doc.png'),
  //       size: '1.5 gb',
  //     },
  //     {
  //       file_name: 'Parenting Guide',
  //       from: 'Doyle Blatteau',
  //       icon: require('@/assets/images/icons/doc.png'),
  //       size: '2.3 mb',
  //     },
  //     {
  //       file_name: 'Class Notes',
  //       from: 'Gwen Greenlow',
  //       icon: require('@/assets/images/icons/doc.png'),
  //       size: ' 30 kb',
  //     },
  //     {
  //       file_name: 'Class Attendance',
  //       from: 'Tom Alred',
  //       icon: require('@/assets/images/icons/xls.png'),
  //       size: '52 mb',
  //     },
  //     {
  //       file_name: 'Company Salary',
  //       from: 'Nellie Dezan',
  //       icon: require('@/assets/images/icons/xls.png'),
  //       size: '29 mb',
  //     },
  //     {
  //       file_name: 'Company Logo',
  //       from: 'Steve Sheldon',
  //       icon: require('@/assets/images/icons/jpg.png'),
  //       size: '1.3 mb',
  //     },
  //     {
  //       file_name: 'Crime Rates',
  //       from: 'Sherlock Holmes',
  //       icon: require('@/assets/images/icons/xls.png'),
  //       size: '37 kb',
  //     },
  //     {
  //       file_name: 'Ulysses',
  //       from: 'Theresia Wrenne',
  //       icon: require('@/assets/images/icons/pdf.png'),
  //       size: '7.2 mb',
  //     },
  //     {
  //       file_name: 'War and Peace',
  //       from: 'Goldie Highnote',
  //       icon: require('@/assets/images/icons/pdf.png'),
  //       size: '10.5 mb',
  //     },
  //     {
  //       file_name: 'Vedas',
  //       from: 'Ajay Patel',
  //       icon: require('@/assets/images/icons/pdf.png'),
  //       size: '8.3 mb',
  //     },
  //     {
  //       file_name: 'The Trial',
  //       from: 'Sirena Linkert',
  //       icon: require('@/assets/images/icons/pdf.png'),
  //       size: '1.5 mb',
  //     },
  //   ],
  // },
  // contacts: {
  //   key: 'name',
  //   data: [
      // {
      //   img: require('@/assets/images/portrait/small/avatar-s-4.jpg'),
      //   name: 'Rena Brant',
      //   email: 'nephrod@preany.co.uk',
      //   time: '21/05/2019',
      // },
      // {
      //   img: require('@/assets/images/portrait/small/avatar-s-2.jpg'),
      //   name: 'Mariano Packard',
      //   email: 'seek@sparaxis.org',
      //   time: '14/01/2018',
      // },
      // {
      //   img: require('@/assets/images/portrait/small/avatar-s-24.jpg'),
      //   name: 'Risa Montufar',
      //   email: 'vagary@unblist.org',
      //   time: '10/08/2019',
      // },
      // {
      //   img: require('@/assets/images/portrait/small/avatar-s-15.jpg'),
      //   name: 'Maragaret Cimo',
      //   email: 'designed@insanely.net',
      //   time: '01/12/2019',
      // },
      // {
      //   img: require('@/assets/images/portrait/small/avatar-s-7.jpg'),
      //   name: 'Jona Prattis',
      //   email: 'unwieldable@unblist.org',
      //   time: '21/05/2019',
      // },
      // {
      //   img: require('@/assets/images/portrait/small/avatar-s-5.jpg'),
      //   name: 'Edmond Chicon',
      //   email: 'museist@anaphyte.co.uk',
      //   time: '15/11/2019',
      // },
      // {
      //   img: require('@/assets/images/portrait/small/avatar-s-25.jpg'),
      //   name: 'Abbey Darden',
      //   email: 'astema@defectively.co.uk',
      //   time: '07/05/2019',
      // },
      // {
      //   img: require('@/assets/images/portrait/small/avatar-s-10.jpg'),
      //   name: 'Seema Moallankamp',
      //   email: 'fernando@storkish.co.uk',
      //   time: '13/08/2017',
      // },
      // {
      //   img: require('@/assets/images/portrait/small/avatar-s-2.jpg'),
      //   name: 'Charleen Warmington',
      //   email: 'furphy@cannibal.net',
      //   time: '11/08/1891',
      // },
      // {
      //   img: require('@/assets/images/portrait/small/avatar-s-25.jpg'),
      //   name: 'Geri Linch',
      //   email: 'insignia@markab.org',
      //   time: '18/01/2015',
      // },
      // {
      //   img: require('@/assets/images/portrait/small/avatar-s-23.jpg'),
      //   name: 'Shellie Muster',
      //   email: 'maxillary@equalize.co.uk',
      //   time: '26/07/2019',
      // },
      // {
      //   img: require('@/assets/images/portrait/small/avatar-s-20.jpg'),
      //   name: 'Jesenia Vanbramer',
      //   email: 'hypotony@phonetist.net',
      //   time: '12/09/2017',
      // },
      // {
      //   img: require('@/assets/images/portrait/small/avatar-s-23.jpg'),
      //   name: 'Mardell Channey',
      //   email: 'peseta@myrica.com',
      //   time: '11/11/2019',
      // },
  //   ],
  // },
}
/* eslint-enable */
