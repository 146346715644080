export default [
  {
    title: 'Operation',
    icon: 'ServerIcon',
    children: [
      {
        title: 'Classification',
        icon: 'LayersIcon',
        route: 'apps-classification-list',
        resource: 'Clasificaciones',
        action: 'read',
      },
      {
        title: 'Confront',
        icon: 'CheckCircleIcon',
        route: 'apps-confronts-warehouse-list',
        resource: 'Confrontas',
        action: 'read',
      },
      {
        title: 'WarehouseLoad',
        icon: 'ArrowUpRightIcon',
        route: 'apps-confronts-load-list',
        resource: 'Confrontas',
        action: 'write',
      },
      // {
      //   title: 'Ship',
      //   icon: 'TruckIcon',
      //   route: 'apps-vendors-ship-list',
      //   resource: 'Proveedores',
      //   action: 'read',
      // },
      {
        title: 'Previous',
        icon: 'PocketIcon',
        route: 'apps-previous-list',
        resource: 'Previos',
        action: 'read',
      },
      {
        title: 'DigitalRecords',
        icon: 'FolderIcon',
        children: [
          {
            title: 'Query',
            route: 'apps-digtalrecord-query',
            icon: 'SearchIcon',
            resource: 'ExpedientesDigitales',
            action: 'read',
          },
          {
            title: 'Downloads',
            route: 'apps-digtalrecord-downloads',
            icon: 'DownloadIcon',
            resource: 'ExpedientesDigitales',
            action: 'read',
          },
          {
            title: 'Management',
            route: 'apps-digtalrecord-management',
            icon: 'CommandIcon',
            resource: 'ExpedientesDigitales',
            action: 'write',
          },
        ],
      },
      {
        title: 'Audit',
        icon: 'ShieldIcon',
        tagVariant: 'light-warning',
        children: [
          {
            title: 'Preaudit',
            route: 'auditoria-previa',
            icon: 'TrelloIcon',
            resource: 'AuditoriaMolex',
            action: 'read',
          },
          {
            title: 'AuditSAAIM3',
            route: 'auditoria-saai-m3',
            icon: 'TrelloIcon',
            resource: 'AuditoriaMolex',
            action: 'read',
          },
          {
            title: 'Query',
            route: 'auditoria-molex',
            icon: 'ActivityIcon',
            resource: 'AuditoriaMolex',
            action: 'read',
          },
          {
            title: 'OperationAuditFollowUp',
            route: 'auditoria-seguimiento-molex',
            icon: 'TrelloIcon',
            resource: 'AuditoriaMolex',
            action: 'read',
          },
        ],
      },
    ],
  },
  {
    title: 'HelpDesk',
    icon: 'SmileIcon',
    children: [
      {
        title: 'Tickets',
        icon: 'InboxIcon',
        route: 'apps-tickets-list',
        resource: 'MesaAyuda',
        action: 'read',
      },
      {
        title: 'Categories',
        icon: 'TagIcon',
        route: 'apps-categories-list',
        resource: 'MesaAyuda',
        action: 'read',
      },
      {
        title: 'UsersActive',
        icon: 'UserIcon',
        route: 'apps-tickets-user-list',
        resource: 'MesaAyuda',
        action: 'read',
      },
      {
        title: 'Incidents',
        icon: 'AlertOctagonIcon',
        route: 'apps-tickets-incidents-list',
        resource: 'MesaAyuda',
        action: 'read',
      },
    ],
  },
  {
    title: 'Security',
    icon: 'LockIcon',
    children: [
      {
        title: 'Users',
        icon: 'UserIcon',
        route: 'apps-users-list',
        resource: 'Usuarios',
        action: 'read',
      },
      {
        title: 'PermissionsMatrix',
        icon: 'FileIcon',
        route: 'apps-security-permissions-matrix',
        resource: 'MatrizPermisos',
        action: 'read',
      },
    ],
  },
  {
    title: 'It',
    icon: 'CpuIcon',
    children: [
      {
        title: 'Equipment',
        icon: 'BriefcaseIcon',
        route: 'apps-equipment-list',
        resource: 'MesaAyuda',
        action: 'read',
      },
      {
        title: 'Softwares',
        icon: 'CloudIcon',
        route: 'apps-software-list',
        resource: 'MesaAyuda',
        action: 'read',
      },
      {
        title: 'Supplier',
        icon: 'PackageIcon',
        route: 'apps-provider-list',
        resource: 'MesaAyuda',
        action: 'read',
      },
      {
        title: 'EquipmentType',
        icon: 'TvIcon',
        route: 'apps-type-equipment-list',
        resource: 'MesaAyuda',
        action: 'read',
      },
      {
        title: 'Brands',
        icon: 'ShoppingBagIcon',
        route: 'apps-brands-list',
        resource: 'MesaAyuda',
        action: 'read',
      },
      {
        title: 'Models',
        icon: 'BookmarkIcon',
        route: 'apps-model-list',
        resource: 'MesaAyuda',
        action: 'read',
      },
      {
        title: 'Banks',
        icon: 'DollarSignIcon',
        route: 'apps-bank-list',
        resource: 'MesaAyuda',
        action: 'read',
      },
      {
        title: 'BankAccount',
        icon: 'CreditCardIcon',
        route: 'apps-account-bank-list',
        resource: 'MesaAyuda',
        action: 'read',
      },
    ],
  },
  {
    title: 'Quality & Security',
    icon: '',
    children: [
      {
        title: 'SOC',
        icon: '',
        route: 'apps-quality-soc',
      },
      {
        title: 'RAMs',
        icon: '',
        route: 'apps-requirement-list',
      },
    ],
  },
  {
    title: 'OperativeCatalogs',
    icon: 'GridIcon',
    children: [
      {
        title: 'Customers',
        icon: 'BriefcaseIcon',
        route: 'apps-customers-list',
        resource: 'Clientes',
        action: 'read',
      },
      {
        title: 'Vendors',
        icon: 'TruckIcon',
        route: 'apps-vendors-list',
        resource: 'Proveedores',
        action: 'read',
      },
      {
        title: 'Receivers',
        icon: 'TargetIcon',
        route: 'apps-receivers-list',
        resource: 'Destinatarios',
        action: 'read',
      },
      {
        title: 'Parts',
        icon: 'ToolIcon',
        resource: 'Partes',
        action: 'read',
        route: 'apps-parts-list',
      },
      {
        title: 'Customs',
        icon: 'MapPinIcon',
        resource: 'Aduanas',
        action: 'read',
        route: 'apps-custom-list',
      },
      {
        title: 'Warehouse',
        icon: 'GlobeIcon',
        resource: 'AlmacenesFiscales',
        action: 'read',
        route: 'apps-bonden-warehouse-list',
      },
      {
        title: 'Transportation',
        icon: 'TruckIcon',
        resource: 'Transpostistas',
        action: 'read',
        route: 'apps-transportation-lines-list',
      },
    ],
  },
  // {
  //   title: 'HumanResources',
  //   icon: 'UsersIcon',
  //   children: [
  //     {
  //       title: 'StaffRequest',
  //       route: 'apps-human-resources-staff-request-list',
  //       resource: 'RecursosHumanos',
  //       action: 'read',
  //     },
  //     {
  //       title: 'NewStaffRequest',
  //       route: 'apps-human-resources-job-new',
  //       resource: 'RecursosHumanos',
  //       action: 'read',
  //     },
  //   ],
  // },
  {
    title: 'SystemCatalogs',
    icon: 'SlidersIcon',
    children: [
      {
        title: 'OrganizationChart',
        children: [
          {
            title: 'HierarchicalLevel',
            route: 'apps-organization-chart-hierarchical-level-list',
            resource: 'RecursosHumanos',
            action: 'read',
          },
          {
            title: 'Area',
            route: 'apps-organization-chart-area-list',
            resource: 'RecursosHumanos',
            action: 'read',
          },
        ],
      },
      {
        title: 'Jobs',
        children: [
          {
            title: 'Job',
            route: 'apps-human-resources-job-list',
            resource: 'RecursosHumanos',
            action: 'read',
          },
          {
            title: 'JobLaboralFunction',
            route: 'apps-human-resources-job-laboral-function-list',
            resource: 'RecursosHumanos',
            action: 'read',
          },
          {
            title: 'JobSchedule',
            route: 'apps-human-resources-job-schedule-list',
            resource: 'RecursosHumanos',
            action: 'read',
          },
          {
            title: 'JobLinePosition',
            route: 'apps-human-resources-job-line-position-list',
            resource: 'RecursosHumanos',
            action: 'read',
          },
        ],
      },
      {
        title: 'HumanResource',
        children: [
          {
            title: 'WorkArea',
            route: 'apps-human-resources-work-area-list',
            resource: 'RecursosHumanos',
            action: 'read',
          },
          {
            title: 'PaymentTypePeriod',
            route: 'apps-human-resources-payment-type-period-list',
            resource: 'RecursosHumanos',
            action: 'read',
          },
          {
            title: 'Profile',
            route: 'apps-human-resources-profile-list',
            resource: 'RecursosHumanos',
            action: 'read',
          },
          {
            title: 'ProfileAcademicTraining',
            route: 'apps-human-resources-profile-academic-training-list',
            resource: 'RecursosHumanos',
            action: 'read',
          },
          {
            title: 'ProfileHability',
            route: 'apps-human-resources-profile-hability-list',
            resource: 'RecursosHumanos',
            action: 'read',
          },
          {
            title: 'ProfileLanguage',
            route: 'apps-human-resources-profile-language-list',
            resource: 'RecursosHumanos',
            action: 'read',
          },
          {
            title: 'ProfileSalary',
            route: 'apps-human-resources-profile-salary-list',
            resource: 'RecursosHumanos',
            action: 'read',
          },
          {
            title: 'ClassContract',
            route: 'apps-human-resources-class-contract-list',
            resource: 'RecursosHumanos',
            action: 'read',
          },
          {
            title: 'LaboralDedication',
            route: 'apps-human-resources-laboral-dedication-list',
            resource: 'RecursosHumanos',
            action: 'read',
          },
          {
            title: 'SchemeTypePayment',
            route: 'apps-human-resources-scheme-type-payment-list',
            resource: 'RecursosHumanos',
            action: 'read',
          },
          {
            title: 'AcademicTraining',
            route: 'apps-human-resources-academic-training-list',
            resource: 'RecursosHumanos',
            action: 'read',
          },
          {
            title: 'LaboralFunction',
            route: 'apps-human-resources-laboral-function-list',
            resource: 'RecursosHumanos',
            action: 'read',
          },
          {
            title: 'Language',
            route: 'apps-human-resources-language-list',
            resource: 'RecursosHumanos',
            action: 'read',
          },
          {
            title: 'HabilityType',
            route: 'apps-human-resources-hability-type-list',
            resource: 'RecursosHumanos',
            action: 'read',
          },
          {
            title: 'Hability',
            route: 'apps-human-resources-hability-list',
            resource: 'RecursosHumanos',
            action: 'read',
          },
          {
            title: 'Schedule',
            route: 'apps-human-resources-schedule-list',
            resource: 'RecursosHumanos',
            action: 'read',
          },
          {
            title: 'Departament',
            route: 'apps-human-resources-departament-list',
            resource: 'RecursosHumanos',
            action: 'read',
          },
          {
            title: 'Scholarship',
            route: 'apps-human-resources-scholarship-list',
            resource: 'RecursosHumanos',
            action: 'read',
          },
          {
            title: 'CivilStatus',
            route: 'apps-human-resources-civil-status-list',
            resource: 'RecursosHumanos',
            action: 'read',
          },
          {
            title: 'WorkPlaces',
            route: 'apps-human-resources-work-place-list',
            resource: 'RecursosHumanos',
            action: 'read',
          },
          {
            title: 'WorkStation',
            route: 'apps-human-resources-work-station-list',
            resource: 'RecursosHumanos',
            action: 'read',
          },
          {
            title: 'Employee Types',
            route: 'apps-human-resources-employee-type-list',
            resource: 'RecursosHumanos',
            action: 'read',
          },
        ],
      },
      {
        title: 'Quality & Security',
        children: [
          {
            title: 'ActionImprovementProblem',
            route: 'apps-action-improvement-problem-list',
            resource: 'GeneralDatos',
            action: 'read',
          },
          {
            title: 'Indicator',
            route: 'apps-indicator-list',
            resource: 'GeneralDatos',
            action: 'read',
          },
          {
            title: 'RequirementDocumentType',
            route: 'apps-requirement-document-type-list',
            resource: 'AccionesMejora',
            action: 'read',
          },
          {
            title: 'RequirementQualityType',
            route: 'apps-requirement-quality-type-list',
            resource: 'AccionesMejora',
            action: 'read',
          },
        ],
      },
    ],
  },
  {
    title: 'Tools',
    icon: 'ToolIcon',
    children: [
      {
        title: 'Bulletins',
        icon: 'SendIcon',
        route: 'apps-bulletins-list',
        resource: 'Boletines',
        action: 'read',
      },
    ],
  },
  {
    title: 'Transferencias',
    icon: 'SendIcon',
    children: [
      {
        title: 'MBGE',
        icon: 'SendIcon',

        // resource: 'Boletines',
        // action: 'read',
        children: [
          {
            title: 'Pedimentos',
            route: 'apps-transfers-mbge-pedimento-list',
          },
        ],
      },
    ],
  },
]
