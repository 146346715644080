export default [
  {
    title: 'Dashboards',
    icon: 'ActivityIcon',
    tagVariant: 'light-warning',
    route: 'pages-user-dashboard',
    resource: 'Tableros',
    action: 'read',
  },
]
