<template>
  <b-navbar-nav class="nav">
    <b-nav-item-dropdown
      id="dropdown-grouped"
      variant="link"
      class="dropdown-language"
      left
      :show.sync="isDropdownOpen"
    >
      <template #button-content>
        <feather-icon
          :badge="downloads.length"
          badge-classes="bg-danger"
          icon="DownloadIcon"
          size="21"
          variant="Success"
        />
      </template>
      <template v-if="downloads.length">
        <b-dropdown-text v-for="downloadItem in downloads" :key="downloadItem.id" variant="Success">
          <div class="download-item">
            <div>
            <h5 class="text-uppercase mb-0">
                {{ downloadItem.name }}
            </h5>
            <h6>
                {{ downloadItem.title }}
            </h6>
            <small>{{ downloadItem.description }}</small>
            </div>
            <!-- <span>{{ downloadItem.name }}</span> -->
            <b-progress
              :value="downloadItem.progress"
              max="100"
              height="1rem"
            >
              <b-progress-bar
                :value="downloadItem.progress"
                :variant="(downloadItem.progress == 100 ? 'success' : 'info')">
                {{ downloadItem.progress }}%
              </b-progress-bar>
            </b-progress>
            {{ common.formatBytes(downloadItem.loaded) }} / {{ common.formatBytes(downloadItem.total) }}
          </div>
        </b-dropdown-text>
      </template>
      <template v-else>
        <b-dropdown-text>
          <feather-icon icon="InfoIcon" />
          <span class="ml-75">{{ $t('AppDigitalRecord.noActiveDownloads') }}</span>
        </b-dropdown-text>
      </template>
    </b-nav-item-dropdown>
  </b-navbar-nav>
</template>

<script>
import {
  BNavItemDropdown,
  // BDropdownItem,
  BDropdownText,
  BProgress,
  BProgressBar,
  BNavbarNav,
} from 'bootstrap-vue'
import { mapGetters } from 'vuex'
import useCommon from '@/libs/common'

export default {
  components: {
    BNavItemDropdown,
    // BDropdownItem,
    BDropdownText,
    BProgress,
    BProgressBar,
    BNavbarNav,
  },
  data() {
    return {
      isDropdownOpen: false,
    }
  },
  computed: {
    ...mapGetters(['downloads']),
  },
  watch: {
    downloads: {
      handler(newDownloads) {
        this.isDropdownOpen = newDownloads.length !== 0
      },
      immediate: true,
    },
  },
  setup() {
    const common = useCommon()

    return {
      common,
    }
  },
}
</script>

<style>
.download-item {
  display: flex;
  flex-direction: column;
  min-width: 400px;
  text-align: center;
}
</style>
