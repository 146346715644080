<template>
  <b-nav-item>
    <feather-icon
      badge-classes="bg-danger"
      size="21"
      icon="ActivityIcon"
      @click="$router.push({name: 'pages-user-dashboard'})"
    />
  </b-nav-item>
</template>

<script>
import {
  BNavItem,
} from 'bootstrap-vue'

export default {
  components: {
    BNavItem,
  },

}
</script>

<style>

</style>
