<template>
  <b-nav-item>
    <feather-icon
      :badge="unread"
      badge-classes="bg-danger"
      size="21"
      icon="BellIcon"
      @click="$router.push({name: 'misc-notifications'})"
    />
  </b-nav-item>
</template>

<script>
import {
  BNavItem,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { ref } from '@vue/composition-api'
import accountService from '@/services/account.service'

export default {
  components: {
    BNavItem,
  },
  directives: {
    Ripple,
  },
  setup() {
    const { fetchNotifications } = accountService()
    const unread = ref(0)
    fetchNotifications(data => {
      unread.value = data.nuevas
    })

    return {
      unread,
    }
  },
}
</script>

<style>

</style>
